
.loginContainer {
  padding: 12px;
  text-align: end;
  min-width: 251px;
}

.loginButton {
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  margin: 6px;
}

.text {
  font-family: 'Lato', sans-serif;
 }
