nav {
  margin-bottom: 55px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.navLinksContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  list-style: none;
  margin: 8px;
  padding: 12px;
  li a {
    color: #7364c4;
    font-size: 24px;
    text-decoration: none;
    &:hover {
      color: darkslateblue;
    }
  } 
}

@media screen and (max-width: 600px) {
  .navLinksContainer {
    flex-direction: row;
    position: absolute;
    top: 8px;
    margin-left: -7%;
    li a {
      font-size: 18px;
      margin: 10px;
    }
  }
  .container {
    margin-left: 7%;
  }
}