nav {
  margin-bottom: 55px;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 195px;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 100%;
  margin-left: 17%;
  li {
    margin: 12px;
    border: #7364c4 solid 1px;
    border-radius: 4px;
    padding: 8px;

    &:hover {
      background-color: darkslateblue;
      cursor: pointer;
      a {
        color: white;
      }
    }

    a {
      color: #7364c4;
      font-size: 20px;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .linksContainer {
    flex-flow: row wrap;
    justify-content: center;
    position: absolute;
    margin-left: -15%;
    top: 54px;
    width: 100%;
    height: 100px;

    li {
      margin: 4px;

      a {
        font-size: 16px;
        margin: 4px;
      }
      &:hover {
        background-color: darkslateblue;
  
        a {
          color: white;
        }
      }
    }
  }
}