.ritualsContentContainer {
  margin: 10%;
  border: darkslateblue solid 1px;
  border-radius: 4px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 65%;
  background-color: lavender;
  .contentHeader {
    text-align: center;
    margin-top: 10%;
  }
  .contentBody {
    margin: 0% 10%;
  }

}



@media screen and (max-width: 600px) {
  .ritualsContentContainer {
    margin: 50% 6.5%;
    height: 80%;
    width: 80%;
  }
}