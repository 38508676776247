@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400&family=Shrikhand&display=swap');

.titleText {
  font-family: 'Shrikhand', cursive;
  font-size: 24px;
}

.text {
  font-family: 'Inconsolata', monospace;
  font-size: 18px;
}


html, body, #root {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  color: black;
  input {
    font-family: inherit;
  }
}
    
              
