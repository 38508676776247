.signUpContainer {
  padding: 12px;
  text-align: end;
  min-width: 251px;
}

.createAccountButton {
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  margin: 6px;
}


