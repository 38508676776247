.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcomeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 18px;
}
  
.buttonContainer {
  margin: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.homeWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 9%;
  width: 100%;
}

button {
  margin: 12px;
  padding: 10px;
  background-color: #7364c4;
  font-size: 16px;
  border: none;
  color: #E1E1E6;
  font-family: inherit;
  &:hover {
    background-color: darkslateblue;
    cursor: pointer;
  }
}

p {
  margin: 12px;
  text-align: center;
}
