.journalsContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.journalEntry {
  margin-bottom: 42px;
  padding: 12px;
  border: #E1E1E6 solid 1px;
  border-radius: 4px;
  width: 100%;
}

.textContainer {
  margin-left: 28.5%;
}

.journalHeader {
  font-size: 26px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 7%;
  span {
    margin-bottom: 8px;
  }
}

.journalPageContainer {
  width: 100%;
  height: 100%;
}

.journalPageContent {
  position: absolute;
  top: 86px;
  margin: 0% 7% 0% 11%;
  width: 64%
}

.journalTitle {
  font-size: 20px;
}

.createJournalButton {
  width: 100px;
}

.journalPrompt {
  font-style: italic;
}

.bgModal {
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  width: 50%;
  height: 45%;
  background-color: lavender;
  border-radius: 4px;
  text-align: center;
  padding: 20px;
  position: relative;
  top: -86px;
  margin-right: 8%;
  overflow: scroll;
  z-index: 1000;
  .titleText {
    color: white;
    -webkit-text-stroke: 1.5px;
    -webkit-text-stroke-color: darkslateblue;
    font-size: 30px;
  }
}

.modalHeader {
  display: flex;
  flex-direction: column;
  .text {
    color: darkslateblue;
    font-style: italic;
    padding: 12px;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 14px;
  font-size: 42px;
  transform: rotate(45deg);
  cursor: pointer;
  color: darkslateblue;
}

textarea {
  height: 200px;
  width: 90%;
  display: block;
  margin: 15px auto;
}

button {
  border-radius: 4px;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .journalPageContainer {
    margin-left: 0px;
  }
  .journalHeader {
    margin-right: 6%;
    text-align: center;
  }
  .modalContent {
    width: 100%;
    height: 50%;
    margin-right:7%
  }
  .textContainer {
    margin-left: 7%;
  }
}